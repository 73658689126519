import service from '@/utils/request';

// @Tags Smart
// @Summary 创建Smart
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.Smart true "创建Smart"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /bizSmart/createSmart [post]
export var createSmart = function createSmart(data) {
  return service({
    url: "/bizSmart/createSmart",
    method: 'post',
    data: data
  });
};

// @Tags Smart
// @Summary 删除Smart
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.Smart true "删除Smart"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /bizSmart/deleteSmart [delete]
export var deleteSmart = function deleteSmart(data) {
  return service({
    url: "/bizSmart/deleteSmart",
    method: 'delete',
    data: data
  });
};

// @Tags Smart
// @Summary 删除Smart
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除Smart"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /bizSmart/deleteSmart [delete]
export var deleteSmartByIds = function deleteSmartByIds(data) {
  return service({
    url: "/bizSmart/deleteSmartByIds",
    method: 'delete',
    data: data
  });
};

// @Tags Smart
// @Summary 更新Smart
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.Smart true "更新Smart"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /bizSmart/updateSmart [put]
export var updateSmart = function updateSmart(data) {
  return service({
    url: "/bizSmart/updateSmart",
    method: 'put',
    data: data
  });
};

// @Tags Smart
// @Summary 用id查询Smart
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.Smart true "用id查询Smart"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /bizSmart/findSmart [get]
export var findSmart = function findSmart(params) {
  return service({
    url: "/bizSmart/findSmart",
    method: 'get',
    params: params
  });
};

// @Tags Smart
// @Summary 分页获取Smart列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取Smart列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /bizSmart/getSmartList [get]
export var getSmartList = function getSmartList(params) {
  return service({
    url: "/bizSmart/getSmartList",
    method: 'get',
    params: params
  });
};